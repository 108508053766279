"use client";

import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import Head from "next/head";
import Script from "next/script";
import { ReactNode } from "react";
import { useCookieSettings } from "~/hooks/useCookieSettings";

const hotjarScript = `
<!-- Hotjar Tracking Code for markus.24sven.rocks -->
<script>
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5069321,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
</script>
`;

export default function Analytics(): ReactNode {
  const { performance } = useCookieSettings();

  return (
    <Head>
      <VercelAnalytics />
      <SpeedInsights />
      {performance && (
        <Script
          dangerouslySetInnerHTML={{ __html: hotjarScript }}
          id="hotjar-script"
        />
      )}
    </Head>
  );
}

"use client";

import { useEffect, useState } from "react";
import { useCart } from "./useCart";
import { getTimeDiffFromExpirationDate } from "~/lib/utils";
import { useDayjsLocale } from "./useLocale";
import { jwtDecode } from "jwt-decode";

type UseExpiresIn = {
  expiresIn: string | null;
  calculated: boolean;
};

export function useExpiredIn(): UseExpiresIn {
  useDayjsLocale();
  const [expiresIn, setExpiresIn] = useState<string | null>(null);
  const [calculated, setCalculated] = useState(false);

  useEffect(() => {
    const check = () => {
      const state = useCart.getState();
      const token = state.reservationToken;
      const fetched = state.reservationTokenFetched;

      if (!fetched) {
        setCalculated(false);
        setExpiresIn(null);
        return;
      }

      if (!token) {
        setExpiresIn(null);
        setCalculated(true);
        return;
      }

      try {
        const { exp } = jwtDecode<{ exp: number }>(token);

        if (typeof exp !== "number") {
          return true;
        }

        const diff = getTimeDiffFromExpirationDate(exp);
        setExpiresIn(diff);
        setCalculated(true);
      } catch {
        setExpiresIn(null);
        setCalculated(true);
      }
    };

    check();

    const interval = setInterval(check, 1000);

    return () => clearInterval(interval);
  }, []);

  return {
    expiresIn,
    calculated,
  };
}

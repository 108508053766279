"use client";

import { faClock, faTicket } from "@fortawesome/pro-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useMemo, useState } from "react";
import { useCart } from "~/hooks/useCart";
import { cn } from "~/lib/utils";
import Link from "next/link";
import { Paths } from "~/lib/Paths";
import { useTranslations } from "~/hooks/useTranslations";
import { useExpiredIn } from "~/hooks/useExpiredIn";

export default function CartTimer(): ReactNode {
  const t = useTranslations();
  const [isHovered, setIsHovered] = useState(false);
  const { items } = useCart();
  const [scaled, setScaled] = useState(false);
  const { expiresIn, calculated } = useExpiredIn();

  const quantity = useMemo(
    () => items.reduce((count, item) => count + item.quantity, 0),
    [items],
  );

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setScaled(false);
  };

  const handleClick = () => {
    setScaled(true);
  };

  return (
    <AnimatePresence>
      {!!expiresIn && (
        <motion.div
          className="fixed right-0 top-[75px] z-40 flex cursor-pointer flex-col flex-nowrap rounded-bl-3xl rounded-tl-3xl bg-backdrop3 p-4"
          initial={{ x: "200%" }}
          animate={{
            x: 0,
            height: isHovered ? (scaled ? 150 : 130) : 80,
            width: isHovered ? (scaled ? 150 : 130) : 80,
          }}
          exit={{ x: "200%" }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          <div className="flex justify-end">
            <AnimatePresence>
              {isHovered && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="absolute left-4 top-3 text-sm text-white"
                >
                  {quantity} {t.tickets}
                </motion.p>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {isHovered && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: { delay: 0.3 },
                  }}
                  exit={{ opacity: 0 }}
                  className="absolute left-4 top-1/2 flex -translate-y-1/2 flex-col gap-2"
                >
                  <Link
                    href={Paths.CART}
                    onClick={(e) => e.stopPropagation()}
                    className="text-xs font-bold text-white hover:underline"
                  >
                    {t.proceed_to_cart}
                  </Link>
                  <Link
                    href={Paths.CHECKOUT}
                    onClick={(e) => e.stopPropagation()}
                    className="text-xs font-bold text-white hover:underline"
                  >
                    {t.proceed_to_checkout}
                  </Link>
                </motion.div>
              )}
            </AnimatePresence>
            <motion.div
              className="relative flex flex-row items-center justify-center"
              animate={{ scale: isHovered ? 1.2 : 1 }}
            >
              <FontAwesomeIcon
                icon={faTicket}
                className={cn("h-6 w-6 text-white transition-all")}
              />
              <FontAwesomeIcon
                icon={faClock}
                style={{ color: "rgba(19, 205, 184, 1)" }}
                className={cn(
                  "absolute bottom-0 right-0 h-3 w-3 transition-all",
                )}
              />
            </motion.div>
          </div>
          {calculated && (
            <p className="absolute bottom-3 left-4 text-xs font-bold text-white">
              {expiresIn}
            </p>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

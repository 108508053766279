"use client";

import { ReactNode, useMemo } from "react";
import { useCart } from "~/hooks/useCart";
import { cn } from "~/lib/utils";
import { motion } from "framer-motion";

export default function NavigationCartAmount(): ReactNode {
  const { uiItems } = useCart();

  const quantity = useMemo(() => {
    if (!uiItems.length) return 0;
    return uiItems.reduce((acc, item) => acc + item.quantity, 0);
  }, [uiItems]);

  if (!quantity) return null;

  return (
    <motion.span
      animate={{ scale: [1, 1.1, 1] }}
      transition={{ duration: 0.25, type: "keyframes" }}
      key={quantity}
      className={cn(
        "leading-xs absolute -right-1 -top-1 flex h-5 flex-row items-center justify-center rounded-full bg-black text-xs font-bold text-white",
        quantity > 9 ? "w-[1.7rem]" : "w-5",
      )}
    >
      {quantity}
    </motion.span>
  );
}
